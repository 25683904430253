@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.ant-tooltip-inner a {
  color: white !important;
}

body {
  height: auto !important;
  color: #2d4764 !important;
}

::-webkit-scrollbar {
  width: 5px;
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0px 1px 2px #aaa;
}
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

::-webkit-scrollbar-corner {
  background: #ccc;
}
::-webkit-scrollbar-corner:hover {
  background: #aaa;
}

* {
  scrollbar-width: thin;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
.mapboxgl-popup {
  width: 300px;
}

.MuiCircularProgress-colorSecondary {
  color: linear-gradient(148.54deg, #ffe925 20.91%, #ffaa39 105.47%) !important;
}

.gray-btn {
  background-color: #3e3637;
}

.ant-slider-track {
  background: linear-gradient(
    148.54deg,
    #ffe925 10.91%,
    #ffaa39 105.47%
  ) !important;
}

.ant-slider-handle {
  border: solid 2px #e5ad71 !important;
}

.ant-divider-horizontal {
  margin: 20px 0px !important;
}
.ant-progress-outer {
  padding-right: 0px !important;
}

.ant-switch-checked {
  background: linear-gradient(
    148.54deg,
    #ffe925 10.91%,
    #ffaa39 105.47%
  ) !important;
}

.anticon-menu-unfold svg {
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.translate-icon-5 {
  transform: translateY(-5%);
}

@media only screen and (max-width: 787px) {
  .header-color-mobile {
    background: linear-gradient(
      226.42deg,
      #1b4963 8.93%,
      #09152f 110.98%
    ) !important;
  }
}
.anticon-menu-fold svg {
  margin-top: 0 !important;
  margin-left: 0 !important ;
}

.icon-list-name:last-child {
  margin-bottom: 20px;
}

.user-select-none {
  user-select: none;
}

.button-bg {
  background: #304864 !important;
}

.search-icon {
  background: #ffd75d;
  border-radius: 50%;
  z-index: 10 !important;
  height: 27px;
  width: 27px;
}

@media only screen and (max-width: 450px) {
  .search-icon {
    display: none;
  }
}

/* .ant-select-selector {
  border-radius: 20px !important;
} */

.ant-select-selection-placeholder {
  color: black !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.ant-select-selection-search-input {
  padding-right: 26px !important;
}

.text-color {
  color: #666666;
}

.kanban-address {
  color: #343434;
  font-weight: 600;
  font-size: 18px;
}
.kanban-address-type {
  font-size: 16px;
}
.kanban-project-cost {
  font-size: 18px;
}

.kanban-project-rooftype {
  font-size: 16px;
}

.kanban-consumption {
  font-size: 18px;
}

.bg-f8 {
  background: #f8f8f8;
}

.infinite-scroll-component:first-child {
  margin-top: 20px;
  margin-bottom: 20px;
}

.listing-card {
  margin-bottom: 20px;
  box-shadow: 1px 1px 10px 0 rgb(45 71 100 / 50%);
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 16px;
}

.font-22 {
  font-size: 22px;
}

.weight-600 {
  font-weight: 600;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.mapboxgl-ctrl-bottom-right {
  visibility: hidden !important;
  display: none !important;
}

.shadow-box:hover {
  cursor: pointer;
  box-shadow: 0 0 50px #ccc;
}

.roof {
  height: 18px;
  width: 20px;
}

.graph {
  height: 18px;
  width: 20px;
}

.address {
  height: 18px;
  width: 20px;
}

.full-map-view {
  height: calc(100% - 52px) !important;
}

.border-1 {
  border-width: 1px;
}

.rounded-20 {
  border-radius: 20px;
}

.border-color {
  border-color: #dfdfdf;
}

.show_image-card-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 40%);
  z-index: 40;
}
.grid-col {
  grid-template-columns: 2fr 1fr 1fr;
}
.makeItSmall {
  padding-left: 75px !important;
  color: #2d4764;
}
@media only screen and (max-width: 450px) {
  .font-small {
    font-size: 10px !important;
  }
  .makeItSmall {
    padding-left: 0px;
    width: auto !important;
    color: #2d4764;
  }
}

@media only screen and (max-width: 600px) {
  .font-small {
    font-size: 10px !important;
  }
  .makeItSmall {
    padding-left: 10px !important;
    width: auto !important;
    color: #2d4764;
  }
}
.text_small {
}
@media only screen and (max-width: 600px) {
  .text_small {
    font-size: 13px !important;
  }
  .gap_change {
    gap: 6px !important;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .hide_on_mobile {
    display: none !important;
  }
  .width_full {
    width: 100% !important;
  }
}

.ant-picker .ant-picker-input,
.ant-picker.ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}
.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 5px;
}

.threeD_loader_component {
  width: 100%;
  height: 100%;
  position: relative;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
.quick-pinch-zoom {
  width: 50%;
}

.main-slider .slick-slide img {
  height: 160px;
}

.main-slider .slick-slide {
  margin: 0 1px;
}

.main-slider .slick-list {
  height: 180px;
}

.helpButton {
  border-radius: 10px !important;
  border-color: rgba(255, 255, 255, 0.6) !important;
  background: rgba(255, 255, 255, 0.6) !important;
  display: flex !important;
  width: 122px !important;
  align-items: center !important;
  justify-content: center !important;
  color: #2d4764 !important;
  box-shadow: rgba(45, 71, 100, 0.5) 1px 1px 10px 0px !important;
}

.openProject {
  border-radius: 10px !important;
  border-color: #304864 !important;
  background: #304864 !important;
  display: inline-flex !important;
  padding: 2px 16.742px 2px 19.258px !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  box-shadow: rgba(45, 71, 100, 0.5) 1px 1px 10px 0px !important;
}
.product-table {
  /* .ant-table-cell {
    min-width: 150px;
  } */
  .larger-name {
    min-width: 210px;
  }
  .extra-larger-name {
    min-width: 280px;
  }
}

.ant-upload-list-text .ant-upload-list-item-name {
  max-width: 300px !important;
}

.MuiAccordionSummary-gutters {
  min-height: 56px !important;
}

.ant-upload-text-icon {
  display: flex !important;
}

.jodit-dialog__footer .jodit-ui-button_ok {
  background-color: #0069d9 !important;
  color: #fff !important;
}

.ant-layout-header {
  color: #2d4764 !important;
}

.anticon {
  vertical-align: 0.1rem !important;
}

.SwitchView {
  @media (max-width: 1245px) {
    display: none;
  }
}

.productforminput {
  background-color: white !important;
}

.productformselectinput .ant-select-selector {
  /* background: none !important; */
  border-radius: 10px !important;
}

.jodit-react-container {
  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }
}

.select-container {
  width: 500px;
}

.input-container {
  width: 500px;
}

@media (max-width: 450px) {
  .input-container {
    width: 100%;
  }
  .select-container {
    width: 30vw;
  }
}

@media (max-width: 1024px) {
  .input-container {
    width: 100%;
  }
  .select-container {
    width: 30vw;
  }
}
.ant-drawer-body {
  padding-right: 0px !important;
}
.ant-modal-wrap {
  overflow: hidden !important;
}
.ant-modal-body {
  overflow-y: scroll !important;
  /* height: 50vh !important; */
}

.ant-collapse-header {
  display: flex !important;
  align-items: center;
  padding: 22px 16px !important;
}
.ant-collapse-ghost {
  background: white;
  width: 100%;
  border-radius: 20px;
}
.ant-collapse-content-box {
  padding-inline: 5% !important;
  border-top: 1px solid #2d4764 !important;
}
.canvasjs-chart-credit {
  visibility: hidden !important;
}

.ant-picker-dropdown {
  z-index: 10670 !important;
}

.ant-btn:hover {
  color: #2d4764 !important;
  border-color: #2d4764 !important;
}

.ant-btn {
  border-radius: 20px !important;
}

.ant-pagination-item-active {
  border-color: #2d4764 !important;
}
.ant-pagination-item:hover {
  border-color: #2d4764 !important;
  color: #2d4764 !important;
}

.ant-pagination-item a {
  color: #2d4764 !important;
}

.ant-btn-primary {
  background: #2d4764 !important;
}
.ant-btn-primary:hover {
  color: white !important;
  border-color: #2d4764 !important;
  background-color: #2d4764 !important;
}
.ant-btn-link {
  color: #2d4764 !important;
}

.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.transition-fade-exit {
  opacity: 1;
}

.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.svg-icon svg {
  position: relative;
  height: 1.5em;
  width: 1.5em;
}

.svg-text svg {
  stroke: #424242;
}

.svg-180 svg {
  transform: rotate(180deg);
}

.form-input {
  padding: 0.375rem;
  background-color: #eeeeee;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #424242;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 1px 2px #8ecae6;
}

.is-fullwidth {
  width: 100%;
}

.bg-white {
  background-color: white;
}

.data-input {
  white-space: pre-wrap;
  border: none;
  padding: 0.5rem;
  color: #424242;
  font-size: 1rem;
  border-radius: 4px;
  resize: none;
  background-color: white;
  box-sizing: border-box;
  flex: 1 1 auto;
}

.data-input:focus {
  outline: none;
  border-radius: 6px;
  background: white;
  backdrop-filter: none;
  position: relative;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  width: 200px;
  overflow: visible;
  min-height: 34px;
  z-index: 50;
}

.shadow-5 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12),
    0 16px 32px rgba(0, 0, 0, 0.12);
}

.svg-icon-sm svg {
  position: relative;
  height: 1.25em;
  width: 1.25em;
}

.svg-gray svg {
  stroke: #9e9e9e;
}

.option-input {
  width: 100%;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.option-input:focus {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  overflow: hidden;
}

.sort-button {
  padding: 0.25rem 0.75rem;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  color: #757575;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.sort-button:hover {
  background-color: #eeeeee;
}

.tr:last-child .td {
  border-bottom: 0;
}

.add-row {
  color: #9e9e9e;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}

.add-row:hover {
  background-color: #f5f5f5;
}

.th {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
}

.th:hover {
  background-color: #f5f5f5;
}

.th-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.td {
  overflow: hidden;
  color: #424242;
  align-items: stretch;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.td-content {
  display: block;
}

.table {
  border-spacing: 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.th:last-child {
  border-right: 0;
}

.td:last-child {
  border-right: 0;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  touch-action: none;
}

.resizer:hover {
  background-color: #8ecae6;
}

.th,
.td {
  white-space: nowrap;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  position: relative;
}

.text-align-right {
  text-align: right;
}

.cell-padding {
  padding: 0.5rem;
}

.d-flex {
  display: flex;
}

.cursor-default {
  cursor: default;
}

.align-items-center {
  align-items: center;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.border-radius-md {
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-margin {
  margin-right: 4px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-75 {
  font-size: 0.75rem;
}

.flex-1 {
  flex: 1;
}

.record-details-drawer .ant-drawer-content {
  background-color: #1f1f1f;
}

.record-details-drawer .ant-drawer-wrapper-body {
  background-color: #1f1f1f;
}

.record-details-drawer .ant-drawer-body {
  padding: 0;
}

.record-details-drawer .ant-divider {
  border-color: #232529;
}

.record-details-drawer .ant-btn-text:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.record-details-drawer .ant-tag {
  border-radius: 4px;
}

.record-details-drawer .ant-input,
.record-details-drawer .ant-input-textarea {
  background-color: #232529;
  border-color: #5c5e63;
  color: #ffffff;
}

.record-details-drawer .ant-input:hover,
.record-details-drawer .ant-input:focus,
.record-details-drawer .ant-input-textarea:hover,
.record-details-drawer .ant-input-textarea:focus {
  border-color: #407ff2;
}

.record-details-drawer .ant-input::placeholder,
.record-details-drawer .ant-input-textarea::placeholder {
  color: #5c5e63 !important;
}

.add-list-modal .ant-modal-content,
.add-list-modal .ant-modal-header {
  background-color: #1f1f1f;
  border-color: #232529;
}

.add-list-modal .ant-modal-title,
.add-list-modal .ant-form-item-label > label {
  color: #ffffff;
}

.add-list-modal .ant-modal-close-x {
  color: #75777c;
}

.add-list-modal .ant-btn-primary {
  background-color: #407ff2;
  border-color: #407ff2;
}

.add-list-modal .ant-btn-primary:hover,
.add-list-modal .ant-btn-primary:focus {
  background-color: #5a91f3;
  border-color: #5a91f3;
}

img.ant-image-preview-img {
  display: inline-block;
}
